@tailwind base;
@layer base {
  html {
    @apply text-gray-800;
    font-family: "Montserrat"; /* Define Montserrat as the primary font */
    font-weight: 500; /* Medium */

  } 
}

@tailwind components;
@tailwind utilities;
